import React, { useEffect, useRef } from 'react';
import type {
  FieldComponentProps,
  Field,
  FieldsDeepUpdate,
} from '../../typescript/types/FieldComponentProps';
import { TextField, TextFieldVariants } from '@mui/material';
import textAreaStyles from '../../../../stylesheets/components/textArea.module.scss';
import { formatErrorMessages } from '../Tools';

export interface TextAreaFieldType extends Field {
  label: string;
  value: string | number | null | undefined;
  rows: number;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
}

export interface TextAreaProps extends FieldComponentProps {
  field: TextAreaFieldType;
  onChange: FieldsDeepUpdate;
  variant?: TextFieldVariants | undefined;
  editClassNames?: string;
  displayClassNames?: string;
}

export interface TextAreaGlimmerProps {
  field: TextAreaFieldType;
  glimmerClassNames?: string;
}

const TextArea = ({
  field,
  mode,
  onChange,
  variant = 'outlined',
  editClassNames,
  displayClassNames,
  testClass,
  errors = [],
}: TextAreaProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const isMounted = useRef(false);
  const formattedErrorMessages = formatErrorMessages(errors, field.label);

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (onChange) onChange(name, value);
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      if (errors.length > 0) {
        if (ref.current) {
          ref.current.focus();
        }
      }
    }
  }, [errors]);

  if (mode === 'edit') {
    return (
      <div className={editClassNames} data-test-class={testClass}>
        <TextField
          error={errors.length > 0}
          data-test-class="textarea"
          className={textAreaStyles.textArea}
          label={field.label}
          inputRef={ref}
          defaultValue={field.value || ''}
          name={field.name}
          onChange={(e) => handleValueChange(e)}
          multiline
          disabled={field.disabled}
          required={field.required}
          inputProps={{
            pattern: field.pattern,
            minLength: field.minLength,
            maxLength: field.maxLength,
          }}
          variant={variant}
          rows={field.rows}
          helperText={formattedErrorMessages}
        />
      </div>
    );
  } else {
    return (
      <div className={displayClassNames}>
        <span className="label">{field.label}</span>
        <span className="value">{field.value}</span>
      </div>
    );
  }
};

export const TextAreaGlimmer = ({
  field,
  glimmerClassNames,
}: TextAreaGlimmerProps) => {
  return (
    <div className={glimmerClassNames}>
      <TextField multiline rows={field.rows} />
    </div>
  );
};

export default TextArea;
